import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userData",
  initialState: {},
  reducers: {
    addUser: (
      state: any,
      action: { payload: { username: string; password: string } },
    ) => {
      state.value = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { addUser } = userSlice.actions;
