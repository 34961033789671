import React, { useEffect, useState } from "react";
import { getCurrentUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const ProtectRoute = (props: any) => {
  const navigate = useNavigate();
  const { Component } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch (error) {
        navigate("/signIn");
      }
    };
    checkAuth();
  }, [navigate]);

  if (!isAuthenticated) {
    return null; // or return a loading spinner if you prefer
  }

  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectRoute;
