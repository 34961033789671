import { Amplify } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { MobileAuth } from "./auth";

// AmplifyJS complains in the event that the browser session storage is empty,
// which it may be in incognito mode, upon creation of a new browser user
// profile, and under other conditions. Here we set an arbitrary unique id
// for the session which ensures that storage is never entirely empty.
if (!localStorage.getItem("revanista-app-session-meta")) {
  const appSessionMeta = { id: uuidv4() };
  localStorage.setItem(
    "revanista-app-session-meta",
    JSON.stringify(appSessionMeta),
  );
}

export class AmplifyConfig {
  static async configure() {
    const authToken = await MobileAuth.getAuthToken();
    Amplify.configure(
      {
        Auth: {
          Cognito: {
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
            identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
            userPoolClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID!,
          },
        },
        API: {
          REST: {
            yield: {
              endpoint: process.env.REACT_APP_YIELD_API_URL!,
              region: "eu-west-1",
            },
          },
        },
      },
      {
        API: {
          REST: {
            headers: async () => {
              return { Authorization: authToken };
            },
          },
        },
      },
    );
  }
}
