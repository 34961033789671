import React, { useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { confirmSignIn } from "aws-amplify/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import revImage from "../../assets/revanista-logo.png";

const ForgetButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "var(--button-color)",
  marginTop: "2px",
  marginBottom: "2px",
  "&:hover": {
    backgroundColor: "var(--button-hover)",
  },
}));

const theme = createTheme();
const RequestNewPassword = () => {
  const [passError, setPassError] = useState(false);
  const { logInUser } = useSelector((state: any) => state.userData);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    newPassword(logInUser.value, data.get("newPassword"));
  };

  const newPassword = async (user: any, newPassword: any) => {
    try {
      const { nextStep } = await confirmSignIn(user);
      if ("name" in nextStep && nextStep.name === "NEW_PASSWORD_REQUIRED") {
        const completeResponse = await confirmSignIn({
          challengeResponse: newPassword,
        });
        console.log("Password updated successfully:", completeResponse);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "newPassword":
        if (!value) {
          setPassError(true);
        } else {
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };

  const handleOnBlur = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "newPassword":
        if (!value) {
          setPassError(true);
        } else {
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer />

      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <img src={revImage} alt="revanista" />
            </div>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography variant="h4">Reset your password</Typography>
              <br />
              {/* <h1>Reset your password</h1> */}
              <span>Set your password</span>

              <TextField
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                autoComplete="newPassword"
                type="password"
                autoFocus
                onChange={handleOnChange}
                onBlur={(e) => handleOnBlur(e)}
                error={passError}
                helperText={passError ? "Please fill new password field" : ""}
              />

              <ForgetButton variant="contained" type="submit" fullWidth>
                Change password
              </ForgetButton>
              <br />
              <Grid container>
                <Grid item xs>
                  <Link to="/signIn">
                    <span className="backLink"> Back to Sign In</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default RequestNewPassword;
