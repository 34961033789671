import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  resetPassword,
  signIn,
  signOut,
} from "aws-amplify/auth";

export class MobileAuth {
  static async getAuthToken() {
    const AuthSession = await fetchAuthSession();
    return AuthSession.tokens?.idToken?.toString() || "";
  }

  static async getUserHotelsPermissions() {
    const AuthSession = await fetchAuthSession();
    return (
      (AuthSession.tokens?.idToken?.payload["custom:permissions"] as string) ||
      ""
    );
  }

  static async signOut() {
    return await signOut();
  }

  static async signIn(username: any, password: any) {
    return await signIn({ username, password });
  }

  static async newPassword(user: any, newPassword: any) {
    return await confirmSignIn({ challengeResponse: newPassword });
  }

  static async forgotPassword(username: any) {
    return await resetPassword(username);
  }

  static async forgotPasswordSubmit(
    username: string,
    confirmationCode: string,
    newPassword: string,
  ) {
    return await confirmResetPassword({
      username,
      newPassword,
      confirmationCode,
    });
  }
}
